<template>
    <div>
        <div class="row">
            <div class="col">
                <h2>{{ $t("invoice.titre_a_valider") }}</h2>
            </div>
            <div class="col-auto ml-auto d-flex">
                <a href="" class="btn ml-3 mb-4 btn-secondary" @click.prevent="setupContractBons(contract_id)">
                    <i><font-awesome-icon :icon="['far', 'file-pdf']" /></i> {{ $t('monte.bons_facturer') }} <i><font-awesome-icon :icon="['fas', 'eye']" /></i>
                </a>
            </div>
        </div>

        <CustomTable
            id_table="contract_avenant_articles_a_valider"
            :items="a_valider"
            :busy.sync="table_busy"
            primaryKey="avenantarticles_id"
            :hide_if_empty="true"
            :preselected_items="preselected_articles"
            ref="aValiderTable"
        />
    </div>
</template>


<script type="text/javascript">
    import { EventBus } from 'EventBus'

    import TableAction from "@/mixins/TableAction.js"
    import Invoice from "@/mixins/Invoice.js"
    import Navigation from "@/mixins/Navigation.js"
    import Config from "@/mixins/Config.js"
    import Contract from "@/mixins/Contract.js"

    import ShutterContract from "@/mixins/shutters-manager/Contract.js"
    import Shutter from '@/mixins/Shutter.js'

    import ContractCleaner from '@/assets/js/cache/cleaners/ContractCleaner'

    export default {
        name: "avalider",
        mixins: [Config, TableAction, Invoice, Navigation, Contract, Shutter, ShutterContract],
        props: {
            contract_id:{
                type: Number
            },
            horse_id:{
                type: Number,
                default: () => ( null )
            },
        },
        data () {
            return {
                validationWaiting: false,
                a_valider: null,
                table_busy: true,
                start_date: new Date(new Date().setMonth(new Date().getMonth() - 3)),
                end_date: new Date(),
                discount_type: [],
                preselected_articles: [],
                events_tab: {
                    'TableAction::goToValideFacturationContractAValider': this.valideFacturationContractAValider,
                    'TableAction::reloadAValider': this.loadAValider,
                    'TableAction::waitingTableAValider': () => {
                        this.table_busy = true
                    },
                },
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.discount_type = await this.loadDiscountType()
                this.loadAValider()
            },
            async loadAValider() {
                this.table_busy = true
                this.a_valider = await this.loadContractArticlesAValider(this.contract_id) 
                this.preselected_articles = this.a_valider.filter(article => ['frais_reservation', 'frais_technique'].includes(article.conditions.contract_conditions.contractconditions_type))
                this.table_busy = false
            },

            async valideFacturationContractAValider(avenant_articles){
                if(!this.validationWaiting){
                    this.validationWaiting = true
                    let paramsToSend = {};
                    paramsToSend['details'] = []
                    
                    if(this.contract_id < 0) {
                        await this.$sync.force(true) 
                    }

                    let contract_id = this.$sync.replaceWithReplicated('contract', this.contract_id)

                    for(let i = 0; i < avenant_articles.length; i++) {
                        const avenantarticles_id = this.$sync.replaceWithReplicated('contract_avenant_articles', avenant_articles[i].avenantarticles_id)
                        let ht = avenant_articles[i].avenantarticles_htunit_before_discount.toString()
                        ht = ht.replace(",", ".")
                        ht = ht.replace(/\s/g, '')
                        ht = ht * 1

                        const discount_type = this.discount_type.find(discount => avenant_articles[i].avenantarticles_discount_type == discount.invoicediscounttype_id)

                        paramsToSend.details.push({
                            author_id: avenant_articles[i].author.tiers_id,
                            tiers_id: avenant_articles[i].tiers.tiers_id,
                            // label: this.getTrad(avenant_articles[i].conditions.contract_conditions.contractconditions_label),
                            label: avenant_articles[i].article.articles_label,
                            qte: avenant_articles[i].avenantarticles_qte,
                            htunit: ht / 100,
                            vat: avenant_articles[i].article.vat.vat_id,
                            horse_id: this.horse_id,
                            articles_id: avenant_articles[i].article.articles_id,
                            contract_id: contract_id,
                            avenantarticles_id: avenantarticles_id,
                            ht_initial: (avenant_articles[i].avenantarticles_ht_initial != null) ? avenant_articles[i].avenantarticles_ht_initial : avenant_articles[i].avenantarticles_ht,
                            tiershorsepartpart: (avenant_articles[i].avenantarticles_quotepart != null) ? avenant_articles[i].avenantarticles_quotepart : 100,
                            tiershorseparttype: 3,
                            discount_type: discount_type,
                            discount_value: avenant_articles[i].avenantarticles_discount_value / 100,
                            description: avenant_articles[i].avenantarticles_comment,
                        })
                    }

                    let result_invoice_details = false

                    if(paramsToSend) {
                        result_invoice_details = await this.createInvoiceDetails(paramsToSend)
                    }

                    if(result_invoice_details) {
                        await this.$sync.force(true, true)
                        ContractCleaner.inst().onMutation([this.contract_id], ['articles'])
                        this.updateContract([this.contract_id])
                        EventBus.$emit('TableAction::stopSpin', true)
                        this.loadAValider()
                        this.setupContractBons(this.contract_id)
                        EventBus.$emit('TableAvenantArticle::ReloadArticle', true)
                        EventBus.$emit('ContractForm::ReloadContract', true)
                    }
                    else {
                        this.failureToast();
                        EventBus.$emit('TableAction::stopSpin', true)
                    }
                    this.validationWaiting = false
                }
            }
        },
        components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
            DateRange: () => import('GroomyRoot/components/Inputs/DateRange'),
        }
    }
</script>
